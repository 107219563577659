import React from 'react';
import { FaLock } from 'react-icons/fa';
import categoryData from '../../../data/categoryData';
import { CategorySprite } from '../../../data/CategorySprite';
import './category-bar.css';

function CategoryBar({ onCategoryClick, availableCategories, activeCategory, className }) {
  const handleCategoryClick = (categoryId) => {
    if (availableCategories.includes(categoryId)) {
      onCategoryClick(categoryId);
    }
  };

  // Helper function to determine if category should use sprite
  const shouldUseSprite = (categoryId) => ['martialArts', 'football', 'hockey', 'basketball', 'languages', 'music', 'childCenter', 'winterSports', 'extremeSports', 'volleyball', 'gymnastics', 'pedagogy', 'speechTherapist', 'dance', 'skills', 'waterSports', 'masterClass', 'intelligence', 'art'].includes(categoryId);

  // THIS sorts categories: available first, then locked
  const sortedCategories = categoryData.sort((a, b) => {
    const aAvailable = availableCategories.includes(a.id);
    const bAvailable = availableCategories.includes(b.id);
    return bAvailable - aAvailable;
  });

  return (
    <div className={`category-bar ${className || ''}`}>
      <CategorySprite />
      {sortedCategories.map(category => {
        const isAvailable = availableCategories.includes(category.id);
        const isActive = activeCategory === category.id;

        return (
          <div
            key={category.id}
            className={`category-item ${isActive ? 'active' : ''} ${!isAvailable ? 'locked' : ''}`}
            onClick={() => handleCategoryClick(category.id)}
          >
            <div className="cb-category-item-container">
              {shouldUseSprite(category.id) ? (
                <svg className='category-icon' style={{ fill: '#72147e' }}>
                  <use href={`#${category.id}`} onError={(e) => console.log('USE tag error:', e)}/>
                </svg>
              ) : (
              <img src={category.icon} alt={category.name} className="category-icon" onError={(e) => {
                console.error(`DEBUG! ---> failed to load icon for ${category.name}:`, category.icon);
                e.target.src = categoryData.find(c => c.id === category.id).icon;
              }}/>
            )}
              {!isAvailable && <FaLock className="cb-lock-icon" />}
            </div>
            <p className="category-title">{category.name}</p>
            {/* {!isAvailable && <FaLock className="cb-lock-icon" />} */}
          </div>
        )
      })}
    </div>
  );
}

export default CategoryBar;