import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../../firebase/FirebaseConfig";
import { collection, deleteDoc, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { FiLock } from "react-icons/fi";
import { FaEnvelope, FaPhoneAlt, FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import analyticsService from "../../../analytics/AnalyticsService";
import "./ActivityProviderDashboard.css";

const ActivityProviderDashboard = () => {
  const [view, setView] = useState("main");
  const [specialOffers, setSpecialOffers] = useState([]);
  const [providerData, setProviderData] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [mainViewData, setMainViewData] = useState(null); // THIS stores main view data
  const [specialOfferViewData, setSpecialOfferViewData] = useState(null); // THIS stores specialOffer view data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  const fetchProviderData = async (userId) => {
    try {
      const providerDocRef = doc(db, "activityProviders", userId);
      const providerDoc = await getDoc(providerDocRef);

      if (providerDoc.exists()) {
        const providerData = providerDoc.data();
        setProviderData(providerData);

        if (providerData.activityId) {
          const activityDoc = await getDoc(
            doc(db, "activities", providerData.activityId)
          );
          setIsComplete(activityDoc.data()?.isComplete || false);
        }

        if (providerData.activityId) {
          const analyticsSummary = await analyticsService.getAnalyticsSummary(
            providerData.activityId
          );

          if (analyticsSummary) {
            const mainData = {
              views: analyticsSummary.views.total || 0,
              clicks: analyticsSummary.clicks.map || 0,
              ctr: analyticsSummary.clicks.mapCTR || 0,
              totalKeyActions: analyticsSummary.contactActions.total || 0,
              keyActionsContact: {
                email: analyticsSummary.contactActions.email || 0,
                call: analyticsSummary.contactActions.call || 0,
                whatsapp: analyticsSummary.contactActions.whatsapp || 0,
                telegram: analyticsSummary.contactActions.telegram || 0,
                total: analyticsSummary.contactActions.total || 0,
              },
            };
            setActivityData(mainData);
            setMainViewData(mainData); // store main view data
          }
        }
      }
    } catch (error) {
      setError(`Ошибка загрузки: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Special Offers when view changes
  useEffect(() => {
    const fetchSpecialOffers = async () => {
      if (view === 'special' && providerData?.activityId) {
        try {
          // Only fetch if we don;t have the data yet
          if (!specialOfferViewData) {
          const specialOffersQuery = query(
            collection(db, 'specialOffers'),
            where('activityId', '==', providerData.activityId)
          );
          const snapshot = await getDocs(specialOffersQuery);
          setSpecialOffers(snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })));
          // Fetch special offers analytics
          const analyticsSummary = await analyticsService.getAnalyticsSummary(providerData.activityId);

          //Set the dashboard with special offer metrics
          if (analyticsSummary?.specialOffer) {
            const specialOfferData = {
              views: analyticsSummary.specialOffer.views || 0,
              clicks: analyticsSummary.specialOffer.clicks || 0,
              ctr: ((analyticsSummary.specialOffer.clicks / analyticsSummary.specialOffer.views) * 100).toFixed(1) || 0,
              totalKeyActions: analyticsSummary.specialOffer.keyActions?.total || 0,
              keyActionsContact: {
                email: analyticsSummary.specialOffer.keyActions?.email || 0,
                call: analyticsSummary.specialOffer.keyActions?.call || 0,
                whatsapp: analyticsSummary.specialOffer.keyActions?.whatsapp || 0,
                telegram: analyticsSummary.specialOffer.keyActions?.telegram || 0,
                total: analyticsSummary.specialOffer.keyActions?.total || 0
              }
            };
            setSpecialOfferViewData(specialOfferData);
            setActivityData(specialOfferData);
          }
        } else {
          // Use cashed s[ecial offers
          setActivityData(specialOfferViewData);
        }
        } catch (error) {
          console.error('Error fetching special offer...', error);
        }
      } else {
        setActivityData(mainViewData); // Restore main view data when switching tab toggle back to Main
      }
    };

    fetchSpecialOffers();
  }, [view, providerData?.activityId, mainViewData, specialOfferViewData]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchProviderData(user.uid);
      } else {
        setError("Пожалуйста, войдите в систему");
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavigateToEditActivityForm = () => {
    if (providerData?.activityId) {
      navigate(`/activity-provider/${providerData.activityId}/edit`);
    } else {
        navigate('/activity-provider/edit-activity');
    }
  };

  if (loading) return <div className='apd-loading'>Загрузка...</div>;
  if (error) return <div className='apd-error-message'>{error}</div>;

  return (
    <div className='activity-provider-dashboard'>
      <div className='apd-list-title'>
        <h2>Добро пожаловать, {providerData?.firstName}</h2>
      </div>

      {/* Add container for subtitle and toggle */}
      <div className='apd-subtitle-row'>
        <span className='apd-list-subtitle'>Здесь отображается аналитика.</span>
        <div className='apd-view-toggle'>
          <button
            className={`view-btn ${view === "main" ? "active" : ""}`}
            onClick={() => setView("main")}
          >
            Главное
          </button>
          <button
            className={`view-btn ${view === "special" ? "active" : ""}`}
            onClick={() => setView("special")}
          >
            Спецпредложение
          </button>
        </div>
      </div>

      <div className={`apd-table-container ${!isComplete ? "apd-blur-background" : ""}`}>
        {/* Main metrics grid */}
        <div className='apd-metrics-grid'>
          <div className='apd-metric-card'>
            <span className='metric-label'>Показы</span>
            <span className='metric-value'>
              {activityData?.views || 0}
            </span>
            {/* <span className='metric-growth'>+5%</span> */}
          </div>
          <div className='apd-metric-card'>
            <span className='metric-label'>Клики</span>
            <span className='metric-value'>{activityData?.clicks || 0}</span>
            {/* <span className='metric-growth'>+5%</span> */}
          </div>
          <div className='apd-metric-card'>
            <span className='metric-label'>CTR</span>
            <span className='metric-value'>{activityData?.ctr}%</span>
            {/* <span className='metric-growth'>+25%</span> */}
          </div>
          <div className='apd-metric-card'>
            <span className='metric-label'>Конверсия</span>
            <span className='metric-value'>{((activityData?.totalKeyActions / activityData?.clicks || 0) * 100).toFixed(1)}%</span>
            {/* <span className='metric-growth'>+5%</span> */}
          </div>
        </div>

        {/* KD + Rank + Reviews container grid */}
        <div className='apd-kd-rank-reviews-grid'>
          {/* Key Actions section */}
          <div className='apd-key-actions'>
            <span className='metric-label'>Ключевые Действия</span>
            <div className='actions-container'>
              <div className='total-actions'>
                {activityData?.keyActionsContact?.total || 0}
                {/* <span className='metric-growth'>+8%</span> */}
              </div>
              <div className='actions-grid'>
                <div className='action-item'>
                  <FaEnvelope size={20} />
                  <span> {activityData?.keyActionsContact?.email || 0}</span>
                </div>
                <div className='action-item'>
                  <FaPhoneAlt size={20} />
                  <span> {activityData?.keyActionsContact?.call || 0}</span>
                </div>
                <div className='action-item'>
                  <FaWhatsapp size={20} />
                  <span> {activityData?.keyActionsContact?.whatsapp || 0}</span>
                </div>
                <div className='action-item'>
                  <FaTelegramPlane size={20} />
                  <span> {activityData?.keyActionsContact?.telegram || 0}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Rank section */}
          <div className='apd-right-column'>
            <div className='apd-rank-card'>
              <div className='card-content'>
                <div>
                  <span className='metric-label'>Ранг</span>
                  <div className='rank-value'>
                    <span className='metric-value blur-value'>#2</span>
                    <span className='metric-subtitle blur-value'>из 150</span>
                  </div>
                  {/* <span className='metric-growth'>+17%</span> */}
                </div>
                <FiLock className='lock-icon' size={24} />
              </div>
            </div>

            {/* Reviews section */}
            <div className='apd-reviews-card'>
              <div className='card-content'>
                <div>
                  <span className='metric-label'>Отзывы</span>
                  <span className='metric-value blur-value'>35</span>
                  {/* <span className='metric-growth'>+15%</span> */}
                </div>
                <FiLock className='lock-icon' size={24} />
              </div>
            </div>
          </div>
        </div>

        {/* Chart placeholder */}
        {view === 'main' ? (
          <div className='apd-chart'>
            <span className='metric-label'>
              Интерактивный чарт (последние 14 дней)
            </span>
            <div className='chart-placeholder'>
              График 'Показы' и 'Клики' будет доступен позже
            </div>
          </div>
        ) : (
          <div className='apd-special-offer-container'>
            {specialOffers.length === 0 ? (
              <div className='apd-no-special-offer'>
                <p>Вы пока еще не добавили Специального Предложения</p>
                <button
                  onClick={() => navigate('/activity-provider/special-offers-list/add-special-offer')}
                  className='apd-add-special-offer-button'
                  disabled={specialOffers.length >= 1}
                >
                  Добавить Специально Предложение
                </button>
              </div>
            ) : (
              <div className='apd-special-offer-header'>
                <h3>{specialOffers[0].title}</h3>
                <div className='apd-special-offer-actions'>
                  <button 
                    onClick={() => navigate(`/activity-provider/special-offers-list/${specialOffers[0].id}/edit`)}
                    className='apd-edit-button'
                  >
                    Редактировать
                  </button>
                  <button
                    onClick={async () => {
                      try {
                        await deleteDoc(doc(db, 'specialOffers', specialOffers[0].id));
                        setSpecialOffers([]);
                      } catch (error) {
                        console.error('Error deleting special offer...', error);
                      }
                    }}
                    className='apd-delete-button'
                  >
                    Удалить
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
       </div>
  
      {/* Show this Modal if the user hasn't published the listing */}
      {!isComplete && providerData?.activityId && (
        <div className='apd-glassmorphism-modal-container'>
          <div className='apd-glassmorphism-modal-body'>
            <p>
              Чтобы родители увидели Ваc на карте, сперва опубликуйте Ваше объявление.
            </p>
            <button
              className='apd-glassmorphism-action-button'
              onClick={handleNavigateToEditActivityForm}
            >
              Опубликовать объявление
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityProviderDashboard;
