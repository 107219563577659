
const categoryData = [
  { id: 'martialArts', name: 'Единоборства', urlSlug: 'edinoborstva', icon: '/category_icons/длядетейру_единоборства.svg' },
  { id: 'football', name: 'Футбол', urlSlug: 'futbol', icon: '/category_icons/длядетейру_футбол.svg' },
  { id: 'hockey', name: 'Хоккей', urlSlug: 'hokkey', icon: '/category_icons/длядетейру_хоккей.svg' },
  { id: 'basketball', name: 'Баскетбол', urlSlug: 'basketbol', icon: '/category_icons/длядетейру_баскетбол.svg' },
  { id: 'languages', name: 'Школа Языков', urlSlug: 'shkola-yazykov', icon: '/category_icons/длядетейру_школа_языков.svg' },
  { id: 'music', name: 'Музыка', urlSlug: 'muzyka', icon: '/category_icons/длядетейру_музыка.svg' },
  { id: 'childCenter', name: 'Детский Центр', urlSlug: 'detskiy-center', icon: '/category_icons/длядетейру_детский_центр.svg' },
  { id: 'winterSports', name: 'Зимний Спорт', urlSlug: 'zimniy-sport', icon: '/category_icons/длядетейру_зимний_спорт.svg' },
  { id: 'extremeSports', name: 'Экстрим', urlSlug: 'ekstrim', icon: '/category_icons/длядетейру_экстрим.svg' },
  { id: 'volleyball', name: 'Волейбол', urlSlug: 'voleybol', icon: '/category_icons/длядетейру_волейбол.svg' },
  { id: 'gymnastics', name: 'Гимнастика', urlSlug: 'gimnastika', icon: '/category_icons/длядетейру_гимнастика.svg' },
  // { id: 'athletics', name: 'Атлетика', urlSlug: 'atletika', icon: '/category_icons/легкая-атлетика.png' },
  { id: 'pedagogy', name: 'ОГЭ, ЕГЭ', urlSlug: 'oge', icon: '/category_icons/длядетейру_огэ_егэ.svg' },
  { id: 'speechTherapist', name: 'Логопед', urlSlug: 'logoped', icon: '/category_icons/длядетейру_логопед.svg' },
  { id: 'dance', name: 'Танцы', urlSlug: 'tantsy', icon: '/category_icons/длядетейру_танцы.svg' },
  { id: 'skills', name: 'Навыки', urlSlug: 'navyki', icon: '/category_icons/длядетейру_навыки.svg' },
  { id: 'waterSports', name: 'Водный Спорт', urlSlug: 'vodnyj-sport', icon: '/category_icons/длядетейру_водный_спорт.svg' },
  { id: 'masterClass', name: 'Мастер-Классы', urlSlug: 'master-klassy', icon: '/category_icons/длядетейру_мастер_класс.svg' },
  { id: 'intelligence', name: 'Интеллект', urlSlug: 'intellekt', icon: '/category_icons/длядетейру_интеллект.svg' },
  { id: 'art', name: 'Искусство', urlSlug: 'iskusstvo', icon: '/category_icons/длядетейру_искусство.svg' }
];

export default categoryData;