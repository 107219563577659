import React, { useEffect, useRef } from 'react';
import { FaRubleSign, FaComments } from 'react-icons/fa';
import './ActivityMarker.css';

function ActivityMarker({ activity, category, onActivityClick, onReviewClick, onBecameVisible, currentZoom, map, isInitialMovementComplete }) {
  const markerRef = useRef(null);
  const markerBecameVisibleRef = useRef(false);

  useEffect(() => {
    let timeoutId;
    const DWELL_TIME = 2000; // 1 second, matching GA standard

    const checkIfInMapBounds = () => {

      if (!markerBecameVisibleRef.current && map) {
        const markerEl = markerRef.current?.parentElement;
        const mapboxMarker = markerEl?._mapboxMarker;

        if (mapboxMarker &&
          map.getBounds().contains(mapboxMarker.getLngLat()) && isInitialMovementComplete) {

          if (timeoutId) clearTimeout(timeoutId);

          timeoutId = setTimeout(() => {
            console.log("DEBUG! ---> Marker view counted after 1s dwell!");
            markerBecameVisibleRef.current = true;
            onBecameVisible?.();
          }, DWELL_TIME);
        }
      }
    };
  
    map?.on('moveend', checkIfInMapBounds);
    checkIfInMapBounds();
  
    return () => {
      map?.off('moveend', checkIfInMapBounds);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [map, onBecameVisible, isInitialMovementComplete, activity.id]);

  const handleClick = () => {
    onActivityClick(activity.id);
  };

  const handleReviewClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onReviewClick(activity);
  };

  const formatReviewWord = (count) => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;
    if (lastDigit === 1 && lastTwoDigits !== 11) {
      return ' отзыв';
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
      return ' отзыва';
    } else {
      return ' отзывов';
    }
  };

  const scale = Math.max(0.5, Math.min(1, currentZoom / 11));

  return (
    <div ref={markerRef} className="activity-marker" style={{ transform: `scale(${scale})` }} onClick={handleClick}>
      <img src={activity.logo || activity.logoUrl} alt={`${activity.businessName} logo`} className="am-logo" />
      <div className="am-info">
        <h3 className="am-business-name">{activity.businessName}</h3>
        <div className="am-details">
          <p className="am-price">
            <FaRubleSign className="am-icon" />
            <span>{activity.price}</span>
          </p>
          <p className="am-reviews" onClick={handleReviewClick}>
            <FaComments className="am-icon" />
            {activity.parentTestimonials ? activity.parentTestimonials.length : 0}
            {formatReviewWord(activity.parentTestimonials ? activity.parentTestimonials.length : 0)}
            {activity.parentTestimonials && activity.parentTestimonials.length > 0 && " (читать)"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ActivityMarker;