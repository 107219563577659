import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import './UserMarker.css';

const UserMarker = ({ map, latitude, longitude }) => {
    const markerRef = useRef(null);

    useEffect(() => {
        if (!map) return;

        // Create marker FIRST and attach to Mapbox
        const el = document.createElement('div');
        el.className = 'user-marker';

        // NOW create wrapper for img and pulse
        const wrapper = document.createElement('div');
        wrapper.className = 'user-marker-wrapper';

        // Create img element for the SVG
        const img = document.createElement('img');
        img.src = '/user_marker/userMarker.svg';
        img.alt = 'User location';

        // Create pulse ring
        const pulseRing = document.createElement('div');
        pulseRing.className = 'pulse-ring';

        // Assemble the elements
        wrapper.appendChild(img);
        wrapper.appendChild(pulseRing);
        el.appendChild(wrapper);

        markerRef.current = new mapboxgl.Marker({
            element: el,
            anchor: 'center'
        })
        .setLngLat([longitude, latitude])
        .addTo(map);

    }, [map]);  

    useEffect(() => {
        if (markerRef.current && map) {
            markerRef.current.setLngLat([longitude, latitude]);
        }
    }, [longitude, latitude]);

    return null;
};

export default UserMarker; 