import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import CitySelector from "../CitySelector";
import "./header.css";

function Header({ selectedCity, onCityChange, className, children, onMenuStateChange }) {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMapHome = location.pathname === "/";
  const navigate = useNavigate();

  const handleMenuClick = () => {
    // If we are on MapHome, use existing behavior
    if (isMapHome) {
      onMenuStateChange?.(true);
      return;
    }
    // For other pages, use local state
    setIsMenuOpen(true);
  };

  const handleRedirect = () => {
    const url = "/";
    navigate(url);
    window.scrollTo(0, 0);
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    onCityChange(newCity);
    localStorage.setItem('selectedCity', newCity);
  };

  return (
    <>
      <header
        className={`header ${isMapHome ? "header--map" : "header--content"} ${!isMapHome && isMenuOpen ? "slide-up" : ""}`}>
        <div className='header-logo-name-location'>
          {children}
          <img
            src='/dd_logo/ДляДетей.ру.png'
            alt='Logo'
            onClick={handleRedirect}
          />
          <p onClick={handleRedirect}>ДляДетей.ру</p>

          {/* BURGER MENU FOR MOBILE */}
          <div className='header-burger-menu' onClick={handleMenuClick}>
            <FaBars size={24} color='#72147e' />
          </div>
          <CitySelector
            selectedCity={selectedCity}
            onChange={handleCityChange}
            className='city-selector'
          />
        </div>
        <button
          className='partner-login-button'
          onClick={() => (window.location.href = "/activity-provider/login")}
        >
          Войти
        </button>
        <button
          className='partner-join-us-button'
          onClick={() =>
            (window.location.href = "/activity-provider/onboarding/step1")
          }
        >
          Присоединиться
        </button>
      </header>

      {/* Render BurgerMenuModal for non-map pages */}
      {!isMapHome && isMenuOpen && (
        <div
          className='menu-modal-overlay'
          onClick={() => setIsMenuOpen(false)}
        >
          <div className='menu-modal' onClick={(e) => e.stopPropagation()}>
            <button
              className='close-button'
              onClick={() => setIsMenuOpen(false)}
            >
              <FaTimes size={24} color='#72147e' />
            </button>
            <nav className='menu-nav'>
              <ul>
                <li><a href='/join-us'>Для организаций</a></li>
                <li><a href='/blog'>Блог</a></li>
                <li><a href='/skazki'>Сказки для детей</a></li>
                <li><a href='/terms-of-service'>Условия использования</a></li>
                <li><a href='/privacy-policy'>Политика конфиденциальности</a></li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
