import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/FirebaseConfig';
// import Header from '../../global/Header/Header';
import MainImage from './main_image/MainImage';
import SectionHeading from '../../section_heading/SectionHeading';
import AboutUs from './about_us/AboutUs';
import WhatWeOffer from './what_we_offer/WhatWeOffer';
import SpecialOffer from './SpecialOffer/SpecialOffer';
import SpecialOfferModal from './SpecialOffer/SpecialOfferModal';
import Groups from './groups/Groups';
import Schedule from './schedule/Schedule';
import OurLocations from './OurLocations/OurLocations';
import Gallery from './gallery/Gallery';
import CTABlock from './cta_block/CTABlock';
import PricingBlock from './pricing/PricingBlock';
import ParentTestimonials from './parent_testimonials/ParentTestimonials';
import ContactBlock from './contact/ContactBlock';
// import Footer from '../../global/Footer/Footer';
import useNavigation from '../../../hooks/useNavigation';
import SEO from '../../global/SEO';
import { cityUrls } from '../../../data/cityUrls';
import Breadcrumbs from '../../global/Breadcrumbs/Breadcrumbs';
import { categoryDisplayNames } from '../../../data/categoryDisplayNames';
import analyticsService from '../../analytics/AnalyticsService';
import './ActivityProviderPage.css';
import { FaMapMarkerAlt } from 'react-icons/fa';

const ActivityProviderPage = ({ providedActivityId, previewData, selectedMarkerAddress }) => {
  const { activityId: urlActivityId} = useParams();
  const specialOfferRef = useRef(null);
  const viewTracked = useRef(false);
  const isSpecialOfferVisible = useIntersectionObserver(specialOfferRef, { threshold: 0.1 });

  const [activityProvider, setActivityProvider] = useState({
    mainImage: '',
    logo: '',
    businessName: '',
    inn: '',
    aboutUs: '',
    whatWeOffer: '',
    specialOffers: [],
    locations: [],
    groups: [],
    schedule: {},
    imageGallery: [],
    pricing: [],
    parentTestimonials: [],
    contactInfo: {}
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { category, activityId } = useParams();
  const [selectedCity, setSelectedCity] = useState(() => {
    return localStorage.getItem('selectedCity') || 'Новосибирск';
  });
  const [showSpecialOfferModal, setShowSpecialOfferModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const generateBreadcrumbs = () => {
    const items = [{ name: 'главная', path: '/'}];

    if (selectedCity) {
      items.push({ 
        name: selectedCity, 
        path: `/${cityUrls[selectedCity]}` 
      });
    }

    if (category) {
      const categoryDisplayName = categoryDisplayNames[category] || category;
      items.push({ 
        name: categoryDisplayName, 
        path: `/activities/${category}` 
      });
    }
    
    if (activityProvider.businessName) {
      items.push({ 
        name: activityProvider.businessName, 
        path: location.pathname 
      });
    }

    return items;
  };

  const breadcrumbItems = generateBreadcrumbs();

  // THIS function tracks certain section (special offers) inside the page.
  function useIntersectionObserver(ref, options) {
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }, options);
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        observer.disconnect();
      };
    }, [ref, options]);
  
    return isIntersecting;
  }

    // Use providedActivityId if available (MapHome), otherwise use urlActivityId (Home)
    const effectiveActivityId = providedActivityId || urlActivityId;

  useEffect(() => {
    // PREVIEW MODE (inside EditActivityForm): use form data directly
    if (previewData) {
      setActivityProvider(previewData);
      setLoading(false);
      return;
    }

    // NORMAL MODE
    if (!effectiveActivityId) {
      setError("No activity provider ID provided (ActivityProviderPage)");
      setLoading(false);
      return;
  }

    const fetchActivityProviderDataFromFirestoreDB = async () => {
      try {
        const activityProviderRef = doc(db, 'activities', effectiveActivityId);
        const activityProviderSnapshot = await getDoc(activityProviderRef);

        if (activityProviderSnapshot.exists()) {
          const data = activityProviderSnapshot.data();

         const isAvailableInSelectedCity =
          data.mainCity === selectedCity ||
          data.locations?.some(loc => loc.city === selectedCity);

          if (isAvailableInSelectedCity) {
         
            // Fetch special offers
              const offersQuery = query(
                collection(db, 'specialOffers'),
                where('providerId', '==', data.providerId)
              );

              const offersSnapshot = await getDocs(offersQuery);
              const offersData = offersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
              }));

              let displayLocations = data.locations;
              if (data.mainCity !== selectedCity) {
                displayLocations = data.locations.filter(loc => loc.city === selectedCity);
              };

            setActivityProvider(prevState => ({ 
              ...prevState,
              id: activityProviderSnapshot.id,
              ...data,
              locations: displayLocations,
              isOnPaidPlan: data.isOnPaidPlan || false,
              specialOffers: offersData,
            }));    

        } else {
          throw new Error(`DEBUG! ---> Activity not available in ${selectedCity}`);
        }
        } else {
            throw new Error (`DEBUG! ---> activityProvider in ${category} category with activityId ${effectiveActivityId} not found`);
          }
      } catch (err) {
        setError(err.message);
      } finally {
      setLoading(false);
      }
    };
    fetchActivityProviderDataFromFirestoreDB();
  }, [category, effectiveActivityId, selectedCity, previewData]);

  const galleryNavigation = useNavigation(activityProvider?.imageGallery?.length || 0);
  // const teamNavigation = useNavigation(activityProvider?.headCoachBlock?.length || 0);
  const testimonialNavigation = useNavigation(activityProvider?.parentTestimonials?.length || 0);

  const handleCityChange = (newCity) => {
    setSelectedCity(newCity);
    localStorage.getItem('selectedCity', selectedCity);
  }

  useEffect(() => {
    if (isSpecialOfferVisible && !viewTracked.current && activityProvider.id) {
      analyticsService.trackSpecialOffer(activityProvider.id, 'view');
      viewTracked.current = true;
    }
  }, [isSpecialOfferVisible, activityProvider.id]);

  const handleSpecialOfferButtonClick = async (offer) => {
    setSelectedOffer(offer);
    setShowSpecialOfferModal(true);

    // THIS tracks the click
    if (activityProvider.id) {
      await analyticsService.trackSpecialOffer(activityProvider.id, 'click');
    }
  };

  const handleTrackSpecialOfferKeyActionsModal = async (type) => {
    if (activityProvider.id) {
      await analyticsService.trackKeyAction(activityProvider.id, type, 'specialOffer');
    }
  };

  const handleTrackContactBlockKeyActions = async (type) => {
    if (activityProvider.id) {
      await analyticsService.trackKeyAction(activityProvider.id, type, 'modal');
    }
  };

  if (error) return <div> Ошибка: {error}</div>
  if (loading) return <div>Загружаю...</div>
  if (!activityProvider) return <div>Загружаю...</div>;

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": activityProvider.businessName,
    "description": activityProvider.aboutUs,
    "image": activityProvider.mainImage,
    "address": {
      "@type": "PostalAddress",
      "addressLocality": selectedCity,
      "addressCountry": "RU"
    },
    "priceRange": activityProvider.price, // If available
    "telephone": activityProvider.contactInfo.phone, // If available
    "email": activityProvider.contactInfo.email, // If available
    "sameAs": [ // Social media links if available
      activityProvider.contactInfo.telegram,
      activityProvider.contactInfo.whatsapp
    ].filter(Boolean),
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumbItems.map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": item.name,
        "item": `https://ddetey.ru${item.path}`
      }))
    }
  };

  return (
    <div className={activityProvider.isPromoted ? 'promoted' : ''}>
      <SEO
        title={`${activityProvider.businessName}, ${selectedCity}`}
        description={`${activityProvider.businessName} – ${activityProvider.aboutUs}`}
        keywords={[
          `${activityProvider.businessName} ${selectedCity}`, 
          `${categoryDisplayNames[category]} ${selectedCity}`, 
          `${categoryDisplayNames[category]} для детей ${selectedCity}`,
          `${categoryDisplayNames[category]} для ребенка ${selectedCity}`,
          activityProvider.businessName,
          activityProvider.category,
          'длядетей.ру', 'ddetey.ru', 'ddetey ru', 'ddeteyru', 'для детей ру', 'длядетейру', 'длядетей ру', 'для детей', 'дети', 'для ребенка', 'сказки', 'слушать скзаку', 'аудиосказки для детей', 'кидс', 'тренер по', 'развитие ребенка', 'карате', 'бесплатно для детей', 'детские занятия', 'детский центр', 'спортивная школа', 'dlya detey ru', 'dlyadetey.ru', 'dlyadeteyru', 'услуги для детей', 'навигатор дополнительного образования', 'репетитор для ребенка', 'егэ', 'огэ', 'единоборства', 'музыка', 'мастер класс для детей', 'логопед', 'танцы', 'брейк данс', 'k pop', 'группа продленного дня', 'музыкальная школа', 'вокал', 'вокал для детей', 'игра на гитаре', 'школа языков', 'английский язык', 'китайский язык', 'языковая школа', 'репетитор', 'подготовка к егэ', 'подготовка к огэ', 'гимнастика для детей', 'детская баскетбольная школа', 'баскетбол', 'футбольный клуб', 'футбольная школа', 'волейбол', 'тенис', 'самбо', 'единоборства', 'лекая атлетика', 'робототехника для детей', 'шахматный клуб', 'школа плавания для детей', 'плавание', 'школа фигурного катания', 'школа сноуборда', 'сапы для детей']} 
        image={activityProvider.mainImage}
        url={`https://ddetey.ru/activities/${category}/${activityId}`}
        ogType="profile"
        schemaMarkup={schemaMarkup}
        canonical={`https://ddetey.ru${location.pathname}`}
      />

      {/* <Header onCityChange={handleCityChange} selectedCity={selectedCity} /> */}
      <Breadcrumbs items={breadcrumbItems} />

        {activityProvider.mainImage && activityProvider.logo && (
        <MainImage 
          mainImage={activityProvider.mainImage}
          logo={activityProvider.logo}
          businessName={activityProvider.businessName}
          isOnPaidPlan={activityProvider.isOnPaidPlan}
          inn={activityProvider.inn || ''}
        />
        )}
        
        {/* LOCATION MARKER + ADDRESS */}
        {!previewData && selectedMarkerAddress && activityProvider.locations?.length > 0 && (
          <div className='app-current-location-banner'>
            <FaMapMarkerAlt />
            <span style={{color: '#323232', opacity: '0.5'}}>
              {selectedMarkerAddress.address}
            </span>

          </div>
        )}

        {activityProvider.aboutUs && (
        <SectionHeading title="О Нас">
          <AboutUs aboutUs={activityProvider.aboutUs} />
        </SectionHeading>
        )}
        {activityProvider.whatWeOffer && (
        <SectionHeading title="Что Мы Предлагаем">
          <WhatWeOffer offers={activityProvider.whatWeOffer} />
        </SectionHeading>
        )}
        {activityProvider.specialOffers && activityProvider.specialOffers.length > 0 && (
        <SectionHeading title="Специальное Предложение">
          <div ref={specialOfferRef}>
          <SpecialOffer 
            title={activityProvider.specialOffers[0].title}
            onSpecialOfferButtonClick={() => handleSpecialOfferButtonClick(activityProvider.specialOffers[0])}
          />
          </div>
        </SectionHeading>
        )}
        
        {showSpecialOfferModal && (
          <SpecialOfferModal 
            offer={selectedOffer}
            mainImage={activityProvider.mainImage}
            onClose={() => setShowSpecialOfferModal(false)}
            onKeyAction={handleTrackSpecialOfferKeyActionsModal}
            contactInfo={activityProvider.contactInfo}
          />
        )}

        {activityProvider.groups && (
          !activityProvider.groups.isHidden || 
          (Object.keys(activityProvider.groups).length > 1 && !activityProvider.groups.isHidden)) && (
        <SectionHeading title="Группы">
          <Groups groups={activityProvider.groups} />
        </SectionHeading>
        )}

        {activityProvider.schedule && !activityProvider.schedule.isHidden && (
        <SectionHeading title="Расписание Занятий">
          <Schedule schedule={activityProvider.schedule} />
        </SectionHeading>
        )}
        <SectionHeading title="Наши Филиалы">
          <OurLocations locations={activityProvider.locations}/>
        </SectionHeading>
        {activityProvider.imageGallery && activityProvider.imageGallery.length > 0 && (
        <SectionHeading title="Галерея" withArrows onNext={galleryNavigation.nextItem} onPrev={galleryNavigation.prevItem}>
          <Gallery images={activityProvider.imageGallery} currentIndex={galleryNavigation.currentIndex} />
        </SectionHeading>
        )}
        {activityProvider.ctaBlock && (
        <CTABlock ctaBlock={activityProvider.ctaBlock} />
        )}

        {/* {activityProvider.HeadCoachBlock && activityProvider.HeadCoachBlock.length > 0 && (
        <SectionHeading title="Наша Команда" withArrows onNext={teamNavigation.nextItem} onPrev={teamNavigation.prevItem}>
          <HeadCoachBlock headCoachBlock={activityProvider.headCoachBlock} currentIndex={teamNavigation.currentIndex} />
        </SectionHeading>
        )} */}

        {activityProvider.pricing && activityProvider.pricing.length > 0 && (
        <SectionHeading title="Стоимость Занятий">
          <PricingBlock pricing={activityProvider.pricing} />
        </SectionHeading>
        )}
        {activityProvider.parentTestimonials && activityProvider.parentTestimonials.length > 0 && (
        <SectionHeading title="Отзывы Родителей" withArrows onNext={testimonialNavigation.nextItem} onPrev={testimonialNavigation.prevItem}>
          <ParentTestimonials testimonials={activityProvider.parentTestimonials} currentIndex={testimonialNavigation.currentIndex}/>
        </SectionHeading>
        )}
        {activityProvider.contactInfo && (
        <SectionHeading title="Контакты">
          <ContactBlock 
            contactInfo={activityProvider.contactInfo}
            onKeyAction={handleTrackContactBlockKeyActions}
          />
        </SectionHeading>
        )}
      {/* <Footer /> */}
    </div>
  );
};

export default ActivityProviderPage;