import React from "react";
import './SectionFour.css';

const SectionOne = () => {
    return (
        <div className="section-four">
                <h2 className="section-four-title">
                    Вы растете вместе с нами
                </h2>
                <span className="section-four-subtitle"> 
                Наш план роста охватывает не только Новосибирск, но и другие города*, открывая перед Вами новые возможности для масштабирования.
                </span>
                <img src="/join_us_block/для-детей-ру-вы-растете-вместе-с-нами.png" alt="City Chart" className="section-four-image" />
                <span className="section-four-subtitle-paragraph"> 
                {/* *присоединяйтесь, если Ваш бизнес в этих городах: Бердск, Академгородок, Искитим, Обь, Краснообск, Кольцово.  */}
                *сейчас: Новосибирск, Бердск, Академгородок, Искитим, Краснообск
                </span>
        </div>
    )
};

export default SectionOne;