import React, { useState, useEffect } from 'react';
import { db } from '../../../../firebase/FirebaseConfig';
import { collection, getDocs, doc, updateDoc, deleteDoc, query, where, writeBatch } from 'firebase/firestore';
import { categoryDisplayNames } from '../../../../data/categoryDisplayNames';
// import Pagination from './Pagination/Pagination';
import './ActivityProvidersList.css';

const subscriptionPlanMap = {
    'default': 'Не выбран',
    'freemium': 'Бесплатный',
    'annual': 'PRO'
};

const subscriptionStatusMap = {
    'default': 'Не выбран',
    'pending': 'В процессе',
    'invoiceSent': 'Счет выставлен',
    'paid': 'Оплачена',
    'ended': 'Закончилась',
    'cancelled': 'Остановлена' 
};

const formatDate = (date) => {
    if (!date) return '–';
    if (date && date.seconds) {
        return new Date(date.seconds * 1000).toLocaleDateString('en-GB')
    }
    if (date instanceof Date) {
        return date.toLocaleDateString('en-GB')
    }
    return 'Ошибка';
};

const ActivityProvidersList = () => {

    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActivityProvidersFromFirestoreDB = async () => {
            try {
                // Fetch activity providers
                const activityProvidersCollection = collection(db, 'activityProviders');
                const activityProviderSnapshot = await getDocs(activityProvidersCollection);

                // Fetch activities to get isComplete status
                const activitiesCollection = collection(db, 'activities');
                const activitiesCollectionSnapshot = await getDocs(activitiesCollection);

                // Create map of providerId to fetch isComplete status
                const completionStatus = {};
                activitiesCollectionSnapshot.docs.forEach(doc => {
                    const data = doc.data();
                    if (data.providerId) {
                        completionStatus[data.providerId] = data.isComplete || false;
                    }
                });

                const activityProvidersList = activityProviderSnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                        isComplete: completionStatus[doc.id] || false
                    };
                });
                setProviders(activityProvidersList);
                setLoading(false)
            } catch (err) {
                console.error("DEBUG: Error fetching activity providers: ", err);
                setError("Failed to load activity providers from Firestore DB");
                setLoading(false);
            }
        };
        fetchActivityProvidersFromFirestoreDB();
    }, []);


    if (loading) {
        return <div className="apl-loading">Загружаю список зарегистрированных поставщиков...</div>;
    }

    if (error) {
        return <div className="apl-error">{error}</div>;
    }

    // const indexOfLastProvider = currentPage * providersPerPage;
    // const indexOfFirstProvider = indexOfLastProvider - providersPerPage;
    // const currentProviders = providers.slice(indexOfFirstProvider, indexOfLastProvider);

    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSubscriptionStatusChange = async (providerId, newStatus) => {
        try {
            const providerRef = doc(db, 'activityProviders', providerId);
            await updateDoc(providerRef, {
                subscriptionStatus: newStatus
            });
            setProviders(prevProviders => 
                prevProviders.map(provider =>
                    provider.id === providerId
                    ? {...provider, subscriptionStatus: newStatus}
                    : provider
                )
            );
        } catch {
            console.error("DEBUG: ---> Error updating subscription status:", error);
        }
    };

    const handleSubscriptionDateChange = async (providerId, newDate) => {
        try {
            const dateObject = new Date(newDate);
            
            const providerRef = doc(db, 'activityProviders', providerId);
            await updateDoc(providerRef, {
                nextPaymentDate: dateObject
            });

            setProviders(prevProviders =>
                prevProviders.map(provider => 
                    provider.id === providerId
                    ? {...provider, nextPaymentDate: {seconds: dateObject.getTime() / 1000}}
                    : provider
                )
            );
        } catch (error) {
            console.error("DEBUG: ---> Error updatig next payment date:", error);
            console.error("DEBUG! ---> Error details:", error.code, error.message);
        }
    };

    const formatDateForInput = (date) => {
        if (!date || !date.seconds) return '';
        return new Date(date.seconds * 1000).toISOString().split('T')[0];
    };

    const formatDateforDisplay = (date) => {
        if (!date || !date.seconds) return '-';
        return new Date(date.seconds * 1000).toLocaleDateString('en-GB');
    };

    const handleDeleteActivityProviderAccount = async (providerId, businessName) => {
        if (window.confirm(`Вы уверены, что хотите удалить аккаунт: ${businessName}?`)) {
            try {
                setLoading(true);

                // Delete activities
                const activitiesRef = collection(db, 'activities');
                const q = query(activitiesRef, where("providerId", "==", providerId));
                const querySnapshot = await getDocs(q);
                const batch = writeBatch(db);
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
            });
                await batch.commit();

                // Delete provider document
                await deleteDoc(doc(db, 'activityProviders', providerId));

                // Update local state
                setProviders(providers.filter(p => p.id !== providerId));

            } catch (error) {
                console.error('DEBUG! ---> Error deleteing account:', error);
                setError('Ошибка! не удалось удалить пользователя');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="activity-providers-list">
            <div className="apl-list-title">
                <h2>Список зарегистрированных поставщиков</h2>
            </div>
            <span className="apl-list-subtitle">Отображается {providers.length} поставщиков</span>
            <div className="apl-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th className="apl-name-column">Название</th>
                                <th className="apl-id-column">ID</th>
                                <th className="apl-category-column">Категория</th>
                                <th className="apl-city-column">Статус</th>
                                <th className="apl-hcname-column">ЛПР</th>
                                <th className="apl-phone-column">Телефон</th>
                                <th className="apl-email-column">Email</th>
                                <th className="apl-city-column">Город</th>
                                <th classname="ap;-actions-column">Действия</th>
                                {/* <th className="apl-subscription-plan-column">Тариф</th>
                                <th className="apl-subscription-status-column">Статус подписки</th>
                                <th className="apl-subscription-plan-end-column">След. платеж</th>
                                <th className="apl-date-added-column">Присоединился</th> */}
                            </tr>    
                        </thead>
                        <tbody>
                            {providers.map((provider) => (
                                <tr key={provider.id}>
                                    <td>
                                        {provider.logoUrl ? (
                                            <img src={provider.logoUrl} 
                                            alt={`${provider.businessName} logo`} 
                                            className="apl-provider-icon"
                                            />
                                        ) : (
                                            <div 
                                                className="apl-provider-icon"
                                                style={{ backgroundColor: '#72147e' }}
                                            ></div>
                                        )}
                                        {provider.businessName}
                                    </td>
                                    <td title={provider.id}>{provider.id.substring(0, 10)}...</td>
                                    <td>{categoryDisplayNames[provider.category] || provider.category}</td>
                                    <td className={`apl-status ${provider.isComplete ? 'complete' : 'incomplete'}`}>
                                        {provider.isComplete ? 'Опубликовано' : 'Не завершено'} 
                                    </td>
                                    <td title={provider.firstName || ''}>
                                        {provider.firstName ?
                                            provider.firstName.length > 30 ? `${provider.firstName.substring(0, 24)}...` : provider.firstName
                                        : ''}
                                    </td>
                                    <td>{provider.contactNumber || ''}</td>
                                    <td>{provider.email || ''}</td>
                                    <td>{provider.mainCity || provider.customCity || ''}</td>
                                    <td>
                                        <button 
                                            onClick={() => handleDeleteActivityProviderAccount(provider.id, provider.businessName)}
                                            className="apl-delete-activity-provider-button"
                                        >
                                                Удалить
                                        </button>
                                    </td>
                                    {/* <td>{subscriptionPlanMap[provider.subscriptionPlan] || provider.subscriptionPlan}</td>
                                    <td>
                                        <select
                                            value={provider.subscriptionStatus}
                                            onChange={(e) => handleSubscriptionStatusChange(provider.id, e.target.value)}
                                            className="apl-subscription-status-select"
                                        >
                                            {Object.entries(subscriptionStatusMap).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="apl-next-payment-date-cell"
                                        onClick={() => document.getElementById(`date-input-${provider.id}`).showPicker()}>
                                        {formatDateforDisplay(provider.nextPaymentDate)}
                                        <input
                                            id={`date-input-${provider.id}`}
                                            type="date"
                                            value={formatDateForInput(provider.nextPaymentDate)}
                                            onChange={(e) => handleSubscriptionDateChange(provider.id, e.target.value)}
                                            className="apl-subscription-next-payment-date"
                                        />
                                    </td> 
                                    <td>{formatDate(provider.joinedDate)}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
            </div>
                {/* <Pagination 
                    providersPerPage={providersPerPage}
                    totalProviders={providers.length}
                    paginate={paginate}
                    currentPage={currentPage}
                /> */}
        </div>
    );
};

export default ActivityProvidersList;